import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button} from 'react-bootstrap';

import Layout from '../../layout/fullpage';

import {Logo} from '../../components';

import { useAuth } from '../../hooks/AuthContext';
import { useNavigate } from 'react-router-dom';

const AuthLoginPage= () => {
  const navigate = useNavigate();
  const { isAuthenticated, loginUser } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser(email, password);
  }

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  return (
    <>
        <Layout title="Login" centered>
            <div className="container p-2 p-sm-4">
                <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
                    <Row className="g-0 flex-lg-row-reverse">
                        <Col lg="5">
                            <Card.Body className="h-100 d-flex flex-column justify-content-center">
                                <div className="nk-block-head text-center">
                                    <div className="nk-block-head-content">
                                        <h3 className="nk-block-title mb-1">Login to Account</h3>
                                        <p className="small">Please sign-in to your account to access dashboard.</p>
                                    </div>
                                </div>
                                <Form action="#" onSubmit={handleSubmit}>
                                    <Row className="gy-3">
                                        <Col className="col-12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="email">Email</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control type="text" id="email" placeholder="Enter email" required value={email} onChange={e => setEmail(e.target.value)}/>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="password">Password</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control type="password" id="password" placeholder="Enter password" required value={password} onChange={e => setPassword(e.target.value)}/>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-12">
                                            <div className="d-grid">
                                                <Button type="submit">Login to account</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Col>
                        <Col lg="7">
                            <Card.Body className="bg-darker is-theme has-mask has-mask-1 h-100 d-flex flex-column justify-content-end">
                                <Logo/>
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>
            </div>
        </Layout>
    </>
  )
}

export default AuthLoginPage;
