import { Link } from 'react-router-dom';
import Image from '../Image/Image';
import './style.css';

function Logo() {
  return (
    <Link to="/" className="logo-link">
        <div className="logo-wrap align-items-center">
            <Image className='logo' src='images/logo.png' alt='logo' />
            <h3>Abyss Cheats</h3>
        </div>
    </Link>
  )
}

export default Logo;
