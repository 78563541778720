import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Dropdown, Button, Offcanvas } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { useDispatch, useSelector } from 'react-redux';

import { Logo, Image, Icon, MediaGroup, MediaText, Media, LinkList, LinkListItem, CustomDropdownToggle, CustomDropdownMenu, Schedule } from '../../../components';


import ToggleSidebar from '../Toggle/Sidebar'
import ToggleNavbar from '../Toggle/Navbar'

import { useLayout, useLayoutUpdate } from './../LayoutProvider'
import { useAuth } from '../../../hooks/AuthContext';
import AxiosInstance from '../../../api/axios-instance';
import { setMe } from '../../../store/actionCreators';


function QuickNav({className,...props}) {
    const compClass = classNames({
        "nk-quick-nav": true,
        [className]: className,
    })
  return (
    <ul className={compClass}>{props.children}</ul>
  )
}

function QuickNavItem({className, ...props}) {
    const compClass = classNames({
        "d-inline-flex": true,
        [className]: className,
    })
  return (
    <li className={compClass}>{props.children}</li>
  )
}

function Header() {
  const dispatch = useDispatch();
  const { logoutUser } = useAuth();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const me = useSelector((state) => state.me);

  const layout = useLayout();
  const layoutUpdate = useLayoutUpdate();

  const compClass = classNames({
    "nk-header nk-header-fixed": true,
    [`is-${layout.headerVariant}`]: layout.headerVariant,
  });

  const navClass = classNames({
    "nk-header-menu nk-navbar": true,
    "navbar-active": layout.headerActive,
    // eslint-disable-next-line
    "navbar-mobile": layout.headerTransition || eval(`layout.breaks.${layout.headerCollapse}`) > window.innerWidth,
  });

  // offcanvas
  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const handleOffcanvasShow = () => setShowOffcanvas(true);

  const handleLogout = () => {
    logoutUser();
  }

  useEffect(() => {
    AxiosInstance.get('/reseller/getMe')
      .then((res) => {
        dispatch(setMe(res.data));
      })
  }, []);

  return (
    <>
        <div className={compClass}>
            <div className="container-fluid">
                <div className="nk-header-wrap">
                <div className="nk-header-logo">
                    <ToggleSidebar variant="zoom" icon='menu' />
                    <ToggleNavbar className="me-2" />
                    <Logo />
                </div>
                {layout.headerActive && <div className="navbar-overlay" onClick={layoutUpdate.headerMobile}></div>}
                <nav className={navClass}></nav>
                <div className="nk-header-tools">
                    <QuickNav>
                        <QuickNavItem>
                            <button className="btn-icon btn btn-zoom btn-sm d-sm-none" onClick={handleOffcanvasShow}>
                                <Icon name="bell"></Icon>
                            </button>
                            <button className="btn-icon btn btn-zoom btn-md d-none d-sm-inline-flex" onClick={handleOffcanvasShow}>
                                <Icon name="bell"></Icon>
                            </button>
                        </QuickNavItem>
                        <Dropdown as={QuickNavItem}>
                            <Dropdown.Toggle bsPrefix as={CustomDropdownToggle}>
                                
                                <div className="d-inline-flex d-sm-none">
                                    <Media shape="circle" size="md">
                                        <Image src='/images/avatar/c.jpg' staticImage thumbnail/>
                                    </Media>
                                </div>
                                <div className="d-none d-sm-flex">
                                    <Media shape="circle">
                                        <Image src='/images/avatar/c.jpg' staticImage thumbnail/>
                                    </Media>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-md" as={CustomDropdownMenu}> 
                                <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                                    <MediaGroup>
                                        <Media size="xl" shape="circle">
                                            <Image src='/images/avatar/c.jpg' staticImage thumbnail/>
                                        </Media>
                                        <MediaText>
                                            <div className="lead-text">{me.username}</div>
                                            <span className="sub-text">Reseller</span>
                                        </MediaText>
                                    </MediaGroup>
                                </div>
                                <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                                    <LinkList>
                                        <LinkListItem to="/admin/profile"><Icon name="user"></Icon><span>My Profile</span></LinkListItem>
                                        <LinkListItem to="/admin/profile"><Icon name="contact"></Icon><span>My Contacts</span></LinkListItem>
                                        <LinkListItem to="/admin/profile-settings"><Icon name="setting-alt"></Icon><span>Account Settings</span></LinkListItem>
                                    </LinkList>
                                </div>
                                <div className="dropdown-content dropdown-content-x-lg py-3">
                                    <LinkList>
                                      <Button className='w-100' onClick={handleLogout}>Log out</Button>
                                    </LinkList>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </QuickNav>
                </div>
                </div>
            </div>
        </div>

        <Offcanvas className="offcanvas-size-lg" placement="end" show={showOffcanvas} onHide={handleOffcanvasClose}>
            <Offcanvas.Header closeButton className="border-bottom border-light">
                <Offcanvas.Title>Recent Notification</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <SimpleBar>
                    <Schedule>
                    </Schedule>
                </SimpleBar>
            </Offcanvas.Body>
        </Offcanvas>
    </>
  )
}

export default Header