import { useSelector } from 'react-redux';
import { Row, Col, Card } from 'react-bootstrap';

import Layout from '../layout/default';

function Home() {
  const me = useSelector((state) => state.me);

  return (
    <Layout title="Analytics">
      <Row className="g-gs">
        <Col sm="12">
          <Card>
            <Card.Body>
              Welcome {me.username}!
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}

export default Home;
