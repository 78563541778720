import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2/src/sweetalert2.js';
import AxiosInstance from '../api/axios-instance';

const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const isAuthenticated = !!token;

  useEffect(() => {
    AxiosInstance.defaults.headers.common['Authorization'] = token;
    AxiosInstance.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      logoutUser();
      return Promise.reject(error);
    });
  }, [token]);

  const loginUser = (email, password) => {
    // Perform authentication logic
    AxiosInstance.post('/reseller/login', {
      email, password
    })
    .then((res) => {
      const t = res.data.token; 
      setToken(t);
      AxiosInstance.defaults.headers.common['Authorization'] = t;
      localStorage.setItem('token', t);
      navigate('/');
    }).catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Wrong password!',
      });
    })
  };

  const logoutUser = () => {
    // Perform logout logic
    localStorage.setItem('token', '');
    setToken('');
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, loginUser, logoutUser, token }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
