import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2/src/sweetalert2.js';

import Layout from '../layout/default';
import DataTable from '../components/DataTable/DataTable';
import AxiosInstance from '../api/axios-instance';

function KeyGen() {
  const [hwid, setHwid] = useState('');
  const [gameId, setGameId] = useState('891');
  const [duration, setDuration] = useState('day');
  const [keyData, setKeyData] = useState([]);

  const tableColumns = [
    {
      name: "Key",
      selector: (row) => row.user_login,
      sortable: false,
    },
    {
      name: "HWID",
      selector: (row) => row.hwid,
      sortable: false,
    },
    {
      name: "Created At",
      selector: (row) => row.created_at,
      sortable: true,
    },
  ];

  const getKeys = () => {
    AxiosInstance.get('/reseller/keys').then((res) => {
      setKeyData(res.data);
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    AxiosInstance.post('/reseller/generateKey', {
      hwid,
      gameId,
      days: duration,
      amount: 1
    }).then((res) => {
      getKeys();
    }).catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Wrong password!',
      });
    })
  }

  useEffect(() => {
    getKeys();
  }, []);

  return (
    <Layout title="Analytics">
      <Row className="g-gs">
        <Col sm="12">
          <Card>
            <Card.Body>
              <Form action="#" onSubmit={handleSubmit}>
                <Row className="gy-3">
                  <Col className="col-12">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="hwid">HWID</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control type="text" id="hwid" placeholder="Enter HWID" required value={hwid} onChange={e => setHwid(e.target.value)}/>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col className="col-6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="game">Game</Form.Label>
                      <div className="form-control-wrap">
                      <Form.Select id="game" required value={gameId} onChange={e => setGameId(e.target.value)}>
                        <option value="891">Rainbow Six Siege</option>
                        <option value="1069">COD: MW3</option>
                      </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col className="col-6">
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="duration">Duration</Form.Label>
                      <div className="form-control-wrap">
                      <Form.Select id="duration" required value={duration} onChange={e => setDuration(e.target.value)}>
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                      </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col className="col-12">
                    <div className="d-grid">
                      <Button type="submit">Generate</Button>
                    </div>
                  </Col>
                  </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="12">
          <Card>
            <Card.Body>
              <DataTable data={keyData} columns={tableColumns} expandableRows actions />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}

export default KeyGen;
