import React from 'react'
import { Routes, Route } from "react-router-dom";

import { ScrollToTop } from '../components';

import AuthLogin from '../pages/auths/AuthLogin';
import Home from '../pages/Home';
import KeyGen from '../pages/KeyGen';
import History from '../pages/History';

import PrivateRoute from '../components/PrivateRoute';

function Router() {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="login" element={<AuthLogin />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/key-gen" element={<KeyGen />} />
          <Route path="/history" element={<History />} />
        </Route>
      </Routes>
    </ScrollToTop>
    
  )
}

export default Router;
