import * as actionTypes from "./actionTypes";

export function setMe(me) {
  return {
    type: actionTypes.SET_ME,
    payload: {
      me
    }
  }
}
