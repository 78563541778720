import * as actionTypes from "./actionTypes";

const initialState = {
  me: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ME:
      return {
        ...state,
        me: action.payload.me
      };
    default:
      return state;
  }
};

export default reducer;
