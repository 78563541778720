import { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import Layout from '../layout/default';
import DataTable from '../components/DataTable/DataTable';
import AxiosInstance from '../api/axios-instance';


function History() {
  const [history, setHistory] = useState([]);

  const getHistory = () => {
    AxiosInstance.get('/reseller/history')
      .then((res) => {
        setHistory(res.data.map(e => ({
          ...e,
          paid: e.paid ? "Paid" : "Unpaid",
          type: e.type.charAt(0).toUpperCase() + e.type.slice(1).toLowerCase(),
        })));
      })
  }

  const tableColumns = [
    {
      name: "Game Id",
      selector: (row) => row.game.name,
      sortable: true,
    },
    {
      name: "Duration",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Paid",
      selector: (row) => row.paid,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) => row.created_at,
      sortable: true,
    },
  ];

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <Layout title="Analytics">
      <Row className="g-gs">
        <Col sm="12">
          <Card>
            <Card.Body>
              <DataTable data={history} columns={tableColumns} expandableRows actions />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}

export default History;
